import shaJs from 'sha.js'
import qs from 'qs';
import randomBytes from 'randombytes'
import merge from 'lodash.merge';
import EventEmitter from 'events';
import "regenerator-runtime";

// if promise hasn't been polyfilled polyfill it just for this file
var _Promise = typeof Promise === 'undefined' ? require('es6-promise').Promise : Promise;

const SESSION_API_URL = 'https://sessions.cimpress.io/v1/sessions';
const PROFILE_API_URL = 'https://profile.cimpress.io/v1/profile';
const ACCOUNT_ID_CLAIM = 'https://claims.cimpress.io/account';
const TEST_USER_CLAIM = 'https://claims.cimpress.io/is_test_user';
const PROFILE_EXPIRY_TIME = 3600 * 1000;
const DEFAULT_OPTIONS = {
  redirectRoute: '',
  domain: 'cimpress.auth0.com',
  audience: 'https://api.cimpress.io/',
  scope: 'offline_access',

  // number of seconds offset - value of 30 means token will be considered expired 30 seconds before it actually expires with the provider
  sessionExpirationOffset: 30,
  // Check to see if the token is expired when the window comes into focus. This is because the expiration timer is sometimes unreliable.
  checkExpirationOnFocus: true
};

// https://auth0.com/docs/flows/authorization-code-flow-with-proof-key-for-code-exchange-pkce
export default class AuthorizationCodeGrantPKCE {

  constructor (options) {
    merge(this, DEFAULT_OPTIONS, options);

    this.redirectUri = window.location.origin + this.redirectRoute;
    this.events = new EventEmitter();

    // listen for changes to localStorage that don't originate from this current window/tab
    window.addEventListener('storage', this.listenToStorage);

    // The sessionExpired event can be unreliable (for example when your computer goes to sleep)
    // So we are double checking that you are logged in when the browser tab gains focus.
    window.addEventListener('visibilityChange', this.handleFocusChange);
  }

  // Utility which enables a developer to subscribe to the events 'listenToStorage' & 'handleFocusChange'
  on = (eventType, ...args) => {
    this.events.on(eventType, ...args);
  };

  // Utility which enables a developer to unsubscribe to the events 'listenToStorage' & 'handleFocusChange'
  removeListener = (eventType, ...args) => {
    this.events.removeListener(eventType, ...args);
  };

  // It is possible to create an infinite loop between 2 tabs/windows if localStorage is modified
  // within this listener - so don't do it :) 
  listenToStorage = (e) => {
    switch (e.key) {
      case 'sessionExpiresAt':
        // check to see if it's being removed or not
        if (e.newValue) {
          try {
            const expiresAt = JSON.parse(e.newValue);
            this.setSessionExpirationTimer(expiresAt);
          } catch (e) {
            this.clearLocalParams();
            console.error(e);
            throw new SyntaxError(`An unexpected error occurred due to invalid sessionExpiresAt value: ${e.message}`, e.stack)
          }
        }
        break;
      default:
    }
  };

  // Check  session status by calling API
  isLoggedIn = async () => {
    const sessionId = localStorage.getItem('sessionId')
    if (sessionId) {
      const sessionData = await this.checkSession(sessionId)
      return ['ACCESS_TOKEN_PENDING', 'ACTIVE'].includes(sessionData.status)
    } else {
      return _Promise.resolve(false);
    }
  };

  // Check for the session's expiration time whenever the the web browser's tab regains focus
  handleFocusChange = async () => {
    if (document.visibilityState === 'visible' && this.checkExpirationOnFocus) {
      // Always check for Session Expiration
      const expiration = localStorage.getItem('sessionExpiresAt');
      if (expiration && expiration <= new Date().getTime() - (this.sessionExpirationOffset * 1000)) {
        this.events.emit('sessionExpired', expiration);
      }
    }
  }

  // Generate a Code Verifier of 32 random bytes
  generateCodeVerifier = () => {
    return this.urlEncode(
      randomBytes(32).toString("base64")
    )
  }

  // Encrypt the 32 random bytes with sha.js
  createCodeChallenge = (codeVerifier) => {
    return this.urlEncode(
      shaJs("sha256")
        .update(codeVerifier)
        .digest("base64")
    )
  }

  // Encode the string so that its properly consumed by the auth0 server
  urlEncode = (str) => {
    return str
      .replace(/\+/g, "-")
      .replace(/\//g, "_")
      .replace(/=/g, "")
  }

  // Generate the code verifier and code challenge and redirect the user to a centralized auth0 page
  login = ({ nextUri = window.location.href, authorizeParams } = {}) => {
    const state = btoa(nextUri);
    const verifier = this.generateCodeVerifier()
    const challenge = this.createCodeChallenge(verifier)
    localStorage.setItem('codeVerifier', verifier);

    let queryStringParams = {
      state,
      response_type: 'code',
      client_id: this.clientID,
      audience: this.audience,
      code_challenge: challenge,
      code_challenge_method: 'S256',
      scope: this.scope,
      redirect_uri: this.redirectUri,
      ...authorizeParams
    }

    window.location = `https://${this.domain}/authorize?${qs.stringify(queryStringParams)}`;
    return _Promise.resolve();
  }

  // If the redirect was from auth0 then a fresh session is created, else the existing session is checked for its validity and the necessary steps are performed
  handleAuthentication = async ({ performRedirect = true } = {}) => {
    const sessionId = localStorage.getItem('sessionId');

    if (this.wasAuth0Redirect()) {
      return this.handleRedirect({ performRedirect });
    } else if (sessionId) {
      // check the status of session else proceed with the login flow
      const sessionData = await this.checkSession(sessionId);
      if (['ACCESS_TOKEN_PENDING', 'ACTIVE'].includes(sessionData.status)) {
        // set the latest expiry for session
        localStorage.setItem('sessionExpiresAt', new Date(sessionData.expiresAt).getTime());
        this.setSessionExpirationTimer();
        return _Promise.resolve(true);
      }
    }
    this.clearLocalParams();
    return _Promise.resolve(false);
  }

  // This method is combination of handleAuthentication() method & login() method. Its the starting point of the authentication process
  ensureAuthentication = (options = {}) => {
    const { forceLogin } = options;
    if (forceLogin) {
      return this.login(options);
    }

    return this.handleAuthentication().then(authenticated => {
      if (authenticated) {
        return _Promise.resolve(true);
      }

      return this.login(options);
    });
  };

  // Checks whether the call is sent back by auth0 server with the code and status as the url parameters.
  wasAuth0Redirect = () => {
    const parsedUrl = this.getFragments();
    return parsedUrl['code'] && parsedUrl['state'];
  }

  // Extracts code and state params from the url and returns a dictionary.
  getFragments = () => {
    if (!window.location.search) { return {}; }

    return window.location.search
      .substring(1)
      .split('&')
      .reduce(function (prev, cur) {
        var kv = cur.split('=');
        prev[kv[0]] = kv[1];
        return prev;
      }, {});
  }

  // If it was a redirect from the auth0 server, then a fresh session is created and the profile information of the user is extracted.
  handleRedirect = async ({ performRedirect }) => {
    try {
      const parsedUrl = this.getFragments();
      const authorizationCode = parsedUrl['code'];
      const state = parsedUrl['state'];
      const nextUri = atob(decodeURIComponent(state));

      const sessionData = await this.createSession(authorizationCode);

      if (sessionData.sessionId) {
        localStorage.removeItem('codeVerifier');
        // Store session related information
        localStorage.setItem('sessionId', sessionData.sessionId);
        localStorage.setItem('sessionExpiresAt', new Date(sessionData.expiresAt).getTime());

        // Call to get Profile information of user
        await this.getProfile(sessionData.sessionId);
        if (performRedirect) {
          window.location = nextUri || '/';
        }
        return true;
      } else {
        return false;
      }
    } catch (err) {
      console.error(err);
      return false;
    }
  }

  // Returns a session for a user in exchange of a Authorization Code and Code Verifier
  createSession = async (authorizationCode) => {
    return this.fetchUtil(SESSION_API_URL, {
        method: 'POST',
        body: JSON.stringify({
          'origin': window.location.origin,
          'clientId': this.clientID,
          'authorizationCode': authorizationCode,
          'codeVerifier': localStorage.getItem('codeVerifier'),
          'redirectUri': this.redirectUri,
        }),
        headers: new Headers({
          Accept: 'application/json',
          'Content-Type': 'application/json'
        })
    });
  }

  // Returns the status of the session in exchange of a session ID
  checkSession = async (sessionId) => {
    if (sessionId) {
      return this.fetchUtil(`${SESSION_API_URL}/${sessionId}/status?cacheBurst=${Date.now()}`)
    } else {
      return _Promise.resolve({});
    }
  }

  // Returns the profile information of a user in exchange of a session ID
  getProfile = async (sessionId) => {
    const profileExpiresAt = localStorage.getItem('profileExpiresAt');
    if (!profileExpiresAt || profileExpiresAt <= new Date().getTime()) {
      const profileResponse = await this.fetchUtil(`${PROFILE_API_URL}/me`, {
          method: 'GET',
        headers: new Headers({
          'x-session-id': sessionId
        })
      });

      const profileData = {
        canonicalId: profileResponse.canonicalId,
        email: profileResponse.email,
        given_name: profileResponse.firstName,
        family_name: profileResponse.lastName || "",
        [ACCOUNT_ID_CLAIM]: profileResponse.accountId,
        name: profileResponse.firstName + (profileResponse.lastName === undefined ? "" : " " + profileResponse.lastName),
        picture: profileResponse.pictureURL,
        [TEST_USER_CLAIM]: profileResponse.isTestUser
      }

      if (profileData.canonicalId) {
          localStorage.setItem('profile', JSON.stringify(profileData));
          localStorage.setItem('profileExpiresAt', new Date().getTime() + PROFILE_EXPIRY_TIME);
      }
      return profileData;
    } else {
      return JSON.parse(localStorage.getItem('profile'));
    }
  }

  // Clears the timer instance 'expiresSessionTimeout' associated with the class 
  clearSessionTimeout = () => this.expiresSessionTimeout = window.clearTimeout(this.expiresSessionTimeout);

  setSessionExpirationTimer = () => {
    const sessionExpiresAt = localStorage.getItem('sessionExpiresAt');

    try {
      this.clearSessionTimeout();
      const timeToWait = sessionExpiresAt - new Date().getTime() - (1000 * this.sessionExpirationOffset);
      this.expiresSessionTimeout = window.setTimeout(
        () => this.events.emit('sessionExpired'), Math.max(Math.min(Math.pow(2, 31) - 1, timeToWait), 0) // Checking max value for setTimeout
      );
    } catch (err) {
      console.error(err);
    }
  };

  // Closes the session in exchange of a session ID and clears the local storage
  logout = async (nextUri, logoutOfFederated) => {
    // Auth0 logout code
    let redirectUrl = nextUri ? window.location.origin + nextUri : window.location.origin;
    let url = `https://${this.domain}/v2/logout`
    url += `?client_id=${this.clientID}`
    url += `&returnTo=${redirectUrl}`
    if (logoutOfFederated) {
      url += '&federated'
    }
    const sessionId = localStorage.getItem('sessionId')
    try {
      if (sessionId) {
        await fetch(`${SESSION_API_URL}/${sessionId}/lifecycle/logout`, {
          method: 'POST',
          headers: new Headers({
            'x-session-id': sessionId
          })
        });
      }
    } catch (err) {
      console.error(err)
    } finally {
      this.clearLocalParams();
      window.location = url;
    }
  }

  // Clears the local storage
  clearLocalParams = () => {
    this.clearSessionTimeout();
    this.clearLocalStorage();
  };

  clearLocalStorage = () => {
    localStorage.removeItem('sessionId');
    localStorage.removeItem('sessionExpiresAt');
    localStorage.removeItem('profile');
    localStorage.removeItem('profileExpiresAt');
  }

  fetchUtil = async (url, options = {}) => {
    try {
      const result = await fetch(url, options);
      if (result.ok) {
        return result.json();
      }
      throw new Error(result);
    } catch (err) {
      console.error(JSON.stringify(err));
      return {}
    }
  }
}
